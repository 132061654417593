import { useState, useEffect } from 'react';
import {
  Spin,
  Layout,
  Breadcrumb,
  Typography,
  Upload,
  Form,
  Input,
  Button,
} from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import Table from '../../components/Table';
import api from '../../service/api';
import * as S from './styles';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const { Title } = Typography;
const { Content } = Layout;

export type SaveNewClientForm = {
  email: string;
  password: string;
  powerBi: string;
  conciliador: string;
  nomeDoGrupo: string;
  clientImage?: File; // Adicionei o tipo opcional aqui
};

const Admin: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    fetchClients();
  }, []);

  async function fetchClients() {
    try {
      const response = await api.get(`/api/Cliente`);
      setTableData(response.data);
    } catch (error) {
      toast.error('Um erro inesperado aconteceu');
    }
  }

  async function onFinish(values: SaveNewClientForm) {
    const formData = new FormData();
    formData.append('Email', values.email);
    formData.append('Password', values.password);
    formData.append('PowerBi', values.powerBi);
    formData.append('Conciliador', values.conciliador);
    formData.append('NomeDoGrupo', values.nomeDoGrupo);

    if (selectedFile) {
      try {
        // Converter o arquivo para um array de bytes
        const arrayBuffer = await selectedFile.arrayBuffer();
        const byteArray = new Uint8Array(arrayBuffer);
        // Converter o array de bytes para uma string base64
        const base64String = arrayBufferToBase64(arrayBuffer);

        // Adicionar a string base64 ao FormData
        formData.append('Img', base64String);
      } catch (error) {
        console.error('Erro ao converter o arquivo para array de bytes:', error);
      }
    }

    setIsSpinning(true);

    try {
      await api.post(`/api/Cliente`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Cliente criado com sucesso');
      fetchClients(); // Atualiza a lista de clientes após a criação
      window.location.reload();
    } catch (error) {
      toast.error('Um erro inesperado aconteceu');
    } finally {
      setIsSpinning(false);
    }
  }

  function arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const handleUpload = (options: any) => {
    const { file, onSuccess, onError } = options;

    setSelectedFile(file as File); // Defina o arquivo selecionado

    // Realizar upload manual se necessário
    const formData = new FormData();
    formData.append('file', file as File);

    api.post('/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        onSuccess?.({}, file);
      })
      .catch((error) => {
        onError?.(error);
      });

    return false; // Previne o upload automático
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 34, color: '#2ce414' }} spin />;

  return (
    <Spin indicator={antIcon} spinning={isSpinning}>
      <Layout className="layout">
        <Content style={{ backgroundColor: 'white', padding: '50px 50px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            {/* Breadcrumb items can be added here if needed */}
          </Breadcrumb>
          <S.SiteLayoutContent>
            <S.ContainerTitle>
              <Title level={3}>Cadastre um novo cliente no painel</Title>
            </S.ContainerTitle>
            <S.FormContainer>
              <Form {...layout} name="nest-messages" onFinish={onFinish}>
                <Form.Item
                  name="nomeDoGrupo"
                  label="Nome do Grupo"
                  rules={[{ required: true, message: 'Por favor insira o Nome Do Grupo' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="E-mail"
                  rules={[{ required: true, message: 'Por favor insira o E-mail' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Senha"
                  rules={[{ required: true, message: 'Por favor insira a senha' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="powerBi"
                  label="Power BI"
                  rules={[{ required: true, message: 'Por favor insira o Power Bi' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="conciliador"
                  label="Link Conciliador"
                  rules={[{ required: true, message: 'Por favor insira o Link Do Conciliador' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="clientImage"
                  label="Imagem Cliente"
                  rules={[{ required: true, message: 'Por favor insira uma imagem' }]}
                >
                  <Upload
                    listType="picture"
                    customRequest={handleUpload}
                    accept=".png, .jpeg, .jpg"
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                  <Button type="primary" htmlType="submit">
                    Salvar
                  </Button>
                </Form.Item>
              </Form>
            </S.FormContainer>
          </S.SiteLayoutContent>
          <Table /> {/* Mantido como estava, sem prop */}
        </Content>
      </Layout>
    </Spin>
  );
};

export default Admin;